@import '../../styles/bootstrap_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.imageGallery {
  &Wrapper {
    position: relative;
  }

  &ShareIcon {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.price {
  font-size: 1.25rem;
  color: #7e7e7e;
}

.disabledColor {
  color: rgba(0, 0, 0, 0.26);
}

.toggleButton {
  &.partiallyAvailable {
    border-color: $luci-remove !important;
    border-style: dashed !important;
    color: $luci-remove;

    &:hover {
      border-color: $luci-remove;
      background-color: $luci-remove;
      color: white;
    }
  }
}

.description {
  white-space: pre-wrap;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  transition: all 0.3s ease;

  &.expanded {
    line-clamp: unset;
    -webkit-line-clamp: unset; /* Remove line clamp */
    max-height: none;
  }
}

.readMore {
  color: $primary;
  cursor: pointer;
  display: inline-block;
}

.bundle {
  &Title {
    font-weight: bold;
  }

  &Items {
    display: flex;
    flex-direction: column;
    margin: 3rem 0;

    .bundleItem {
      width: auto;
      padding: 1rem 0;
      border-bottom: 1px solid #e0e0e0;

      &:first-child {
        border-top: 1px solid #e0e0e0;
      }
    }
  }
}
