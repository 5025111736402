@import './styles/bootstrap_variables';
@import '~bootstrap/scss/bootstrap';
@import '~react-image-gallery/styles/scss/image-gallery';
@import '~react-loading-skeleton/dist/skeleton.css';

.content {
  flex: 1 1 auto;
}

.wrapper {
  max-width: 1300px;
  margin: 0 auto;
}

.fill-window {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

legend {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

a {
  text-decoration: none;
}

// Used on shipping method radio list items
.list-group-item .form-check-input.is-invalid ~ .invalid-feedback {
  display: none;
}

// Table
.table {
  &_header--border_bottom th,
  &_header--border_bottom thead th {
    border-bottom: 2px solid #dee2e6;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

// https://getbootstrap.com/docs/4.3/layout/overview/#responsive-breakpoints
@include media-breakpoint-down(sm) {
  .table {
    thead {
      display: none;
    }

    &_wrapper {
      overflow-x: auto;
    }

    &_row-odd {
      background: #edeff1;
    }

    td {
      display: block;
      text-align: right;
      width: 100% !important;
      padding-left: 50%;
      white-space: normal;
      position: relative;

      &::before {
        content: attr(data-title);
        position: absolute;
        display: block;
        text-align: left;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
        top: 50%;
        left: 0.75rem;
        transform: translate(0, -50%);
      }
    }
  }
}
