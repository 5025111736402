@import '../../styles/bootstrap_variables';

.container {
  position: relative;
  cursor: auto;
  text-transform: none;
  user-select: none;
}

.summaryComplete {
  background: #f5f4f4;
  padding: 20px;
}

.totals {
  & + & {
    margin-top: 20px;
  }

  &Currency {
    float: right;
  }
}

.finalTotal {
  font-weight: bold;
}

.closeButton {
  margin-left: auto !important;
}

.shopping_cart {
  border: 1px solid #d6d6d6;
  background: #ffffff;
  width: 340px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 2px;
  padding: 16px;
  z-index: 200;
  box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.12),
    0 12px 15px 0 rgba(0, 0, 0, 0.11);

  &_header {
    color: #4a4a4a;
    font-size: 18px;
    display: flex;
  }

  &_total {
    float: right;
  }

  &_list {
    list-style-type: none;
    padding-left: inherit;
    overflow: auto;
    padding-left: 0px;
    max-height: 200px;
    margin-bottom: 20px;
  }

  &_item {
    &_image {
      float: left;
      margin-right: 10px;
      width: 70px;
      height: 80px;
    }

    & + & {
      margin-top: 8px;
    }

    &_remove {
      color: #007aff;
      font-size: 11px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

// TODO: Better fix for cart on mobile
@media (max-width: 370px) {
  .shopping_cart {
    width: 320px;
  }
}

.emptyCart {
  font-weight: 500;
  text-align: center;
  padding: 1em;
}

.header {
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  padding: 1em;
}

.cartBundle {
  background-color: #f5f4f4;
  border: 1px solid #d6d6d6;
  padding: 10px;
  margin-top: 8px;
  margin-right: 5px;
}

.bundleTitle {
  display: inline-block;
  font-weight: 500;
  margin-bottom: 10px;
}

.cart-icon {
  color: #515783;
  font-size: 24px;
  margin-right: 7px;
  float: left;
}

// .button {
//   @include bless-button($btn-primary);
// }

// .button_disabled {
//   @include bless-button($btn-disabled);
// }

.shopping_cart_item a {
  color: $logo-color-grey;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}

.shopping_cart_item a:hover {
  text-decoration: none;
}

.shopping_cart_item_image_area {
  width: 70px;
  height: 80px;
  overflow: hidden;
  position: relative;
  float: left;
  margin-right: 10px;
}

.shopping_cart_item_image {
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.linkHover:hover {
  color: $link-button-hover;
}
