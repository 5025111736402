.wrapper {
  position: relative;
}

.content {
  position: relative;
  z-index: 10;
}

.iconPunch {
  width: 26px;
}

.iconBackground {
  position: absolute;
  width: 320px;
  height: 320px;
  fill: #ececec;
  bottom: 0;
  left: 0;
  transform: translate(-45%, 30%);
}

.logo {
  display: block;
  max-width: 250px;
  max-height: 75px;
  margin: 0 auto;
  padding-bottom: 12px;
}

.punchCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &Mark {
    flex: 0 0 calc(100% / 5);
    display: flex;
    justify-content: center;
    padding: 8px 0;
  }

  &Punch {
    width: 40px;
    height: 40px;
    background: #ec008c;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    margin: 4px;
  }
}

.bottomText {
  text-transform: uppercase;
  text-align: center;
  color: #ec008c;
}
