.freeShippingLeft {
  text-align: center;
  margin: 1rem 0;
}

.bold,
.boldUppercase {
  font-weight: bold;
}

.boldUppercase {
  text-transform: uppercase;
}
