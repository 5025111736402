@import '../../styles/bootstrap_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.inventory_layout_toggle {
  float: right;
}

.grid_icon {
  display: inline-block;

  & > .layer {
    width: 100%;
    position: absolute;
    left: 0;
  }

  &__fill {
    position: relative;
    width: 26px;
    height: 26px;
    vertical-align: middle;
    margin: 6px 0;

    & > .layer {
      height: 12px;

      &__primary {
        top: 0;
      }

      &__secondary {
        bottom: 0;
      }

      & > span {
        position: absolute;
        display: block;
        width: 12px;
        height: 100%;
        background: $font-color-default;
        border-radius: $border-radius;

        &:first-child {
          left: 0;
        }

        &:nth-child(2) {
          right: 0;
        }
      }
    }
  }
}

.hamburger_icon {
  width: 26px;
  height: 26px;
  position: relative;
  margin: 6px 0;

  & span {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    background: $font-color-default;
    border-radius: $border-radius;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2) {
      top: 10px;
    }

    &:nth-child(3) {
      top: 20px;
    }
  }
}

.gutterLeft {
  padding-left: calc($grid-gutter-width / 2);
}

.productGroupSkeleton {
  padding-bottom: 100%;
  line-height: 2 !important;
  height: 0;
}

.searchForm {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  white-space: nowrap;
  align-items: center;
  column-gap: 1rem;
  row-gap: 1rem;
  justify-content: space-between;
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-wrap: wrap;
  }
}
