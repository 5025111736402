.rewardHeader {
  text-align: center;
  color: #ec008c;
}

.rewardSubheader {
  font-size: 1.25rem;
  line-height: 24px;
  margin-bottom: 32px;
}

.card {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08),
    0px 2px 4px rgba(201, 200, 200, 0.5);
  border-radius: 12px;
  background-color: #fff !important;
  max-width: 370px;
  width: 100%;
  padding: 24px;

  & + & {
    position: absolute;
  }
}

@for $i from 0 through 2 {
  .card_#{$i} {
    transform: translate($i * -10px, $i * 10px);
    z-index: 10 - $i;
    // opacity: 1 - ($i / 3);
  }
}

.summaryCard {
  position: relative;
  z-index: 20;
}

.summaryTitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 12px;
}

.thankYouText {
  color: #ec008c;
  margin-top: 16px;
}

.thankYouMsg {
  font-size: 1.25rem;
  line-height: 24px;
}

.socialLogo {
  height: 100%;
  width: 1.75rem;
}

.socialLogoContainer {
  display: inline-block;
  flex: 0 0 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  text-align: center;
}

.contactLink {
  display: flex;
  align-items: center;

  &Text {
    flex-grow: 1;
  }
}
