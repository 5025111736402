@import '../../styles/bootstrap_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$mobile-nav-width: 240px;

.nav {
  height: 100%;
  width: $mobile-nav-width;
  overflow-y: auto;
  transform: translate(-100%);
  transition: transform 0.3s ease-out;
  z-index: 999;
  box-shadow: none;
  position: relative;
  top: 0;

  &_background {
    width: 100%;
    z-index: 650;
    box-shadow: 3px 0 12px rgba(0, 0%, 0%, 0.25);
  }

  &,
  &_background {
    position: fixed;
    left: 0;
    background: #fff;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0 1rem 1rem;
  }

  &_link {
    display: flex;
    display: flex;
    padding: 16px;

    &,
    &:hover {
      color: #333;
      text-decoration: none;
    }
    &:hover {
      box-shadow: 0 3px 0 $luci-primary;
      @include media-breakpoint-down(md) {
        box-shadow: 0 1px 0 $luci-primary;
      }
    }

    &__carrot {
      display: relative;
      height: auto;
    }

    &__active {
      box-shadow: 0 3px 0 $luci-primary;
      @include media-breakpoint-down(md) {
        box-shadow: 0 1px 0 $luci-primary;
      }
    }
  }

  .storeProfile {
    display: none;
    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0.5rem;
      max-height: 35vh;

      box-shadow: 0px 20px 30px 20px rgba(0, 0, 0, 0.4);
    }
  }
  &_logo {
    padding: 0;
    position: relative;
    width: 110px;
    height: 52px;
    overflow: hidden;
    margin: 0 auto;
    border-color: transparent;
    &:hover {
      box-shadow: 0 0 0 0 transparent;
    }
    border-color: transparent;
    &:hover {
      box-shadow: 0 0 0 0 transparent;
    }
    &_mobile {
      margin-left: 64px;
    }

    img,
    &__loading {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90px;
      transform: translate(-50%, -50%);
    }

    &__loading {
      height: 2rem;
    }
  }

  &_cart_button {
    border: none;
    display: inline-flex;
    padding: 4px 6px;
    color: $font-color-default;

    &__active {
      color: #fff;

      &:hover,
      &:focus,
      &:active {
        color: #fff !important;
      }
    }
  }
}

.cart_wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 54px;
}

.menu_button {
  @include media-breakpoint-down(md) {
    display: block;
  }
  @include media-breakpoint-down(md) {
    display: block;
  }
  transition: transform 0.3s ease-out;
  align-items: center;
  align-items: center;
  position: absolute;
  padding: 0;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 45px;
  height: 52px;
  transform: translate(0);
}

.hamburger_icon {
  width: 30px;
  height: 26px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  margin: 0 auto;
  z-index: 999;
  z-index: 999;

  & span {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    border-radius: 6px;
    opacity: 1;
    right: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2) {
      top: 10px;
    }

    &:nth-child(3) {
      top: 20px;
    }
  }

  .mobile_nav button & span {
    background: #fff;

    &:nth-child(1) {
      top: 10px;
      transform: rotate(135deg);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      top: 10px;
      transform: rotate(-135deg);
    }
  }
}

.shoppingCartPreview {
  position: absolute;
  bottom: 0;
  right: 0;
}

.close_background {
  opacity: 0;
  background: $logo-color-grey;
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: opacity 0.3s ease-out;
  z-index: -1;
}

.timer {
  text-transform: lowercase;
  font-weight: bold;
}

.storeProfile {
  display: none;
  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    padding-top: 30px;
    box-shadow: 10px 5px 15px 10px rgba(0, 0, 0, 0.25);
  }
}

.storeProfile {
  display: none;
  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    padding-top: 30px;
    box-shadow: 10px 5px 15px 10px rgba(0, 0, 0, 0.25);
  }
}

// https://getbootstrap.com/docs/4.3/layout/overview/#responsive-breakpoints
@include media-breakpoint-up(md) {
  .nav {
    position: static;
    height: auto;
    width: auto;
    background: none;
    overflow-y: visible;
    transform: translate(0);
    transition: transform 0s;
    z-index: auto;

    &_wrapper {
      max-width: 1400px;
      max-width: 1400px;
      margin: 0 auto;
      position: relative;
    }

    &_background {
      border-bottom: 2px solid #e4e4e4;
      height: auto;
      box-shadow: none;
    }

    li {
      float: left;
    }
  }

  .close_background {
    display: none;
  }

  .menu_button {
    display: none;
  }
}

// https://getbootstrap.com/docs/4.3/layout/overview/#responsive-breakpoints
@include media-breakpoint-down(md) {
  .mobile_nav {
    & .nav {
      padding-top: 5vw;
      padding-top: 5vw;
      transform: translate(0);
      box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
    }

    &__transition .nav {
      transform: translate(-100%);
      box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
    }

    & .close_background {
      opacity: 0.8;
      z-index: 990;
      width: 100%;
    }

    &__transition .close_background {
      opacity: 0;
      z-index: 990;
      width: 100%;
    }

    .menu_button {
      transform: translate($mobile-nav-width);
    }
  }

  .cart_wrapper {
    height: 52px;
  }
}
