@import '../../styles/bootstrap_variables';

.footer {
  background: #484949;
  flex-shrink: 0;
  margin-top: 1.5rem;
  position: relative;
}

.logo {
  width: 15px;
  vertical-align: text-bottom;
  margin-right: 5px;
}

.terms {
  font-size: 0.75rem;
}

.notes + .notes {
  margin-left: 10px;
  border-left: 1px solid $font-color-default;
  padding-left: 10px;
}

.linkGroupHeader {
  color: #ffffff;
}

.link {
  color: #ececec;
}
