.notification {
  color: #ececec;
  background-color: #00000f;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2000;
  right: 0;
  padding: 1rem 2rem;
}

.link {
  color: #0d6efd;
  text-decoration: underline;

  &:visited {
    color: #0d6efd;
  }

  &:hover,
  &:active,
  &:focus {
    color: #4f90f1;
  }
}
