@import '../../styles/bootstrap_variables';

.checkout {
  &_cart_list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &_total {
    border-top: 2px solid #e4e4e4;
    font-weight: bolder;
    font-size: 1.125rem;
  }
}

.wordWrap {
  word-wrap: break-word;
}

.paypalLogo {
  width: 100px;
  margin-bottom: 8px;
}
