@import '../../styles/bootstrap_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.relative {
  position: relative;
}

.not_found_svg {
  float: right;
}

.polygon {
  animation: float 1s infinite ease-in-out alternate;
  stroke-width: 1rem;
}

.polygon2 {
  animation-delay: 0.2s;
}

.polygon3 {
  animation-delay: 0.4s;
}

.polygon4 {
  animation-delay: 0.6s;
}

.polygon5 {
  animation-delay: 0.8s;
}

@keyframes float {
  100% {
    transform: translateY(20px);
  }
}

@include media-breakpoint-down(md) {
  .not_found_svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 0px;
    margin-left: -190px;
  }
}
