@import '../../styles/bootstrap_variables';

.checkout {
  &_cart_list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &_total {
    border-top: 2px solid #e4e4e4;
    font-weight: bolder;
    font-size: 1.125rem;
  }
}

.linkInlineButtons {
  padding: 0;
  margin: 0;
  border: none;
  vertical-align: baseline;
}

.shippingCompact {
  min-height: 40px;
}

.wordWrap {
  word-wrap: break-word;
}

.cards_image {
  margin-right: 1rem;
}

.paypalLogo {
  width: 100px;
}

.paymentRadio label + label {
  margin-top: 8px;
}
