@import './styles/bootstrap_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.appWrapper {
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
}

.contentWrapper {
  max-width: 1400px;
  margin: 0px auto;
}

.shipping_banner {
  background: #000;
  color: #fff;
  line-height: 1.5rem;
  position: fixed;
  z-index: 650;
  right: 0;
  left: 0;
  top: 0;
  height: $free-Shipping-banner-height;
}

.preview_banner {
  color: #fff;
  line-height: 1.5rem;
  text-align: center;
  background-color: #d92408;
  height: $preview-banner-height;
}
.app_header {
  height: $header-height;
  display: grid;
}

.app_header:has(> .shipping_banner) {
  margin-top: $free-Shipping-banner-height;
  height: $header-height + $free-Shipping-banner-height;
}

.app_header:has(> :only-child) {
  margin-bottom: 2rem;
}

.dimmer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 500;
}
