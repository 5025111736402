.paginationControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &Details {
    margin-left: 12px;
    margin-right: 12px;
  }
}
