@import '../../styles/bootstrap_variables';

.checkout {
  &_cart_list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &_total {
    border-top: 2px solid #e4e4e4;
    font-weight: bolder;
    font-size: 1.125rem;
  }

  &_border_top {
    border-top: 2px solid #e4e4e4;
  }
}

.right {
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
}
