// https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

// Fonts
@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue'), local('BebasNeue-Regular'),
    url('../fonts/BebasNeueRegular.woff2') format('woff2'),
    url('../fonts/BebasNeueRegular.woff') format('woff'),
    url('../fonts/BebasNeue-Regular.otf') format('otf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bison Bold';
  src: local('Bison Bold'), local('Bison-Bold'),
    url('../fonts/Bison-Bold.woff2') format('woff2'),
    url('../fonts/Bison-Bold.woff') format('woff'),
    url('../fonts/Bison-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: local('Proxima Nova Rg'), local('ProximaNova-Regular'),
    url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('../fonts/ProximaNova-Regular.woff') format('woff'),
    url('../fonts/ProximaNova-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$font-family-sans-serif: 'Proxima Nova Rg', system-ui, -apple-system, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$headings-font-family: 'Bison Bold', system-ui, -apple-system, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$font-color-default: #4a4a4a;

// Colors
$logo-color-yellow: #fed141;
$logo-color-orange: #ff9d6e;
$logo-color-pink: #f7a7a4;
$logo-color-light-pink: #e3b9b3;
$logo-color-medium-turquoise: #4ac1e0;
$logo-color-blue-sapphire: #005d83;
$logo-color-grey: #54565a;
$logo-color-black: #000000;

// Paparazzi
$paparazzi-color-primary: #e50589;
$paparazzi-color-secondary: #000000;
$paparazzi-color-tertiary: #e50589;
$paparazzi-color-success: #79b54a;
$paparazzi-color-alert: #aa1d32;
$paparazzi-color-warning: #ed7b22;

$rich-black: #54565a;

$disabled-grey: #d7d5d5;
$error-red: #ce1126;

$error-backdrop-pink: #f7d0c7;

// Bootstrap Colors
$luci-primary: $paparazzi-color-primary;
$luci-secondary: $paparazzi-color-secondary;
$luci-remove: $logo-color-grey;
$luci-success: $paparazzi-color-success;
$luci-info: $paparazzi-color-tertiary;
$luci-danger: $paparazzi-color-alert;
$luci-warning: $paparazzi-color-warning;
$luci-disabled: $disabled-grey;

// Buttons
$btn-primary: $luci-primary;
$btn-secondary: $luci-secondary;
$btn-remove: $luci-remove;
$btn-success: $luci-success;
$btn-danger: $luci-danger;
$btn-warning: $luci-warning;
$btn-disabled: $luci-disabled;

$border-radius: 2px;
$border-radius-lg: 2px;
$border-radius-sm: 2px;

$link-button-hover: #fff;

$theme-colors: (
  'primary': $luci-primary,
  'secondary': $luci-secondary,
  'success': $luci-success,
  'info': $luci-info,
  'warning': $luci-warning,
  'danger': $luci-danger,
);

$preview-banner-height: 68px;
$free-Shipping-banner-height: 42px;
$header-height: 54px;
$mobile-header-height: 54px;
