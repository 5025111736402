.background {
  color: #ececec;
  background-color: #00000f;
  padding: 10px;
}

.bold,
.boldUppercase {
  font-weight: bold;
}

.boldUppercase {
  text-transform: uppercase;
}
