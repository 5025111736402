@import '../../styles/bootstrap_variables';

.shopping_cart {
  &_item {
    list-style: none;
    padding-bottom: 32px;

    & + & {
      border-top: 2px solid #e4e4e4;
      padding-top: 32px;
    }

    & a {
      color: $logo-color-grey;
      text-decoration: none;
      font-size: 1.125rem;

      &:hover {
        text-decoration: none;
      }
    }

    &_image_area {
      padding-right: 10px;
    }
  }

  &_quantity {
    flex: 0 0 70px;
  }
}

.align_center_right {
  align-items: center;
  display: flex;
  justify-content: right;
}

.small {
  margin-right: 5px;
  padding-bottom: 16px;

  & + & {
    padding-top: 16px;
  }

  & a {
    font-size: 0.875rem;
  }
}

.remove_button {
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}
