@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.filterList {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.bottomButton_wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
}

.loadingWrapper {
  padding: 1rem;
  transition: opacity 0.25s ease-out, visibility 0.25s ease-out,
    max-height 0s 0.5s linear;
  opacity: 1;
  visibility: visible;

  &.fadeOut {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
  }
}

.contentWrapper {
  transition: opacity 0.25s 0.25s ease-in, visibility 0.25s 0.25s ease-in;
  opacity: 0;
  visibility: hidden;
  padding-bottom: 2vw;

  &.fadeIn {
    visibility: visible;
    opacity: 1;
  }
}

@include media-breakpoint-down(md) {
  .filterList {
    display: block;
    position: fixed;
    padding: 16px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: #fff;
    overflow-y: scroll;
  }

  .contentWrapper {
    padding-bottom: 60px;
  }
}
