$ig-small-screen: 768px;
$ig-xsmall-screen: 480px;

.image-gallery {
  @media (hover: hover) and (pointer: fine) {
    &-icon:hover {
      color: grey;
    }
  }

  &-slides {
    border: 1px solid grey;
  }

  &-thumbnail {
    overflow: hidden;
  }

  &-override &-thumbnail {
    transition: none !important;
    border: 4px solid transparent;

    &.active,
    &:hover,
    &:focus {
      border: 4px solid grey;

      @media (max-width: $ig-small-screen) {
        border: 4px solid grey;
      }
    }
  }
}

@mixin contain-image {
  background-color: white;
  object-fit: contain;
}

.image-gallery {
  .igo_contain &-content &-slide &-image {
    @include contain-image;
  }

  .igo_contain &-thumbnail-image {
    @include contain-image;
  }
}

@mixin cover-image {
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  background-color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.image-gallery {
  .igo_cover &-content &-slide &-image {
    @include cover-image;
  }

  .igo_cover &-slides > div {
    padding-bottom: 100%;
  }

  .igo_cover &-thumbnail {
    &-inner {
      overflow: hidden;
      position: relative;
      padding-bottom: 100%;
    }

    &-image {
      @include cover-image;
    }
  }
}
