.pay_form_iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.wrapper {
  width: calc(100% + 20px);
  height: 460px;
  position: relative;
  margin-left: -10px;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
}

.cards {
  &_header {
    margin: 1rem 0 0.5rem;
  }

  &_image {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

.alertRed > div {
  color: #d92408;
  background-color: #f7dbd6;
  border: 1px solid #d92408;
  border-radius: 3px;
}
