@import '../../styles/bootstrap_variables';

.socialLogo {
  height: 100%;
  width: 1.75rem;
}

.socialLogoContainer {
  display: inline-block;
  flex: 0 0 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  text-align: center;
}

.contactLink {
  display: flex;
  align-items: center;

  &Text {
    flex-grow: 1;
  }
}
