@import '../../styles/bootstrap_variables';

.icon {
  width: 20px;
  padding-bottom: 2px;
  fill: currentColor;
}

.quantity {
  display: inline-block;
  min-width: 30px;
  line-height: 39px;
  text-align: center;
  vertical-align: bottom;
  flex: 1;
}

.buttons {
  width: 40px;
  flex: 0 0 40px;
  padding: 6px;
}

.container {
  display: flex;
}
