.avatar {
  border-radius: 50%;
}

@mixin aspect-ratio($width, $height) {
  padding-top: calc(100% * ($height / $width));
}

.videoContainer {
  overflow: hidden;
  @include aspect-ratio(16, 9);
  position: relative;
}

.videoContainer > iframe {
  position: absolute;
  border: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
