/* InventoryItem.module.scss */

@import '../../styles/bootstrap_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.productGroup {
  &Skeleton {
    padding-bottom: 100%;
    line-height: 2 !important;
    height: 0;
  }

  &Item {
    border: none;

    a {
      text-decoration: none;
      color: $body-color;

      &:hover {
        color: $link-hover-color;
      }
    }

    &:hover .productGroupImage {
      border: 1px solid $link-hover-color;
    }
  }

  &Image {
    border: 1px solid lightgrey;
  }

  &Name {
    font-size: 0.875rem;
    font-weight: bold;
  }

  &Price {
    font-size: 0.75rem;
    color: $gray-700;
  }

  &GridContainer {
    display: grid;
    gap: 4px;

    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  &Images-0,
  &Images-1 {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }

  &Images-2 {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  &Images-2 .productGroupGridItem {
    grid-column: span 1;
    grid-row: span 2;

    img {
      aspect-ratio: 1 / 2;
    }
  }

  &Images-3 {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  &Images-3 .productGroupGridItem:nth-child(1) {
    grid-row: span 2;
    grid-column: span 1;
  }

  &Images-4 {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  &GridItem {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #d3d3d3;
    box-sizing: border-box;
    background-color: white; /* Creates fake background for the contained image */

    &:hover {
      border: 1px solid $link-hover-color;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      aspect-ratio: 1 / 1;
    }
  }
}
