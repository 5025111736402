.share {
  &Wrapper &Button {
    background: rgba(255, 255, 255, 0.5);
    width: 36px;
    height: 36px;
    padding: 0;
    min-width: 36px;
    border-radius: 50%;
    text-transform: uppercase;
    margin: 8px;
  }

  &Icon {
    background: #333;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    width: 32px;
    height: 32px;

    > svg {
      width: 20px;
      height: 20px;
    }
  }

  &IconText {
    display: flex;
    align-items: center;
  }

  &Link {
    display: flex;
  }

  &Carrot {
    position: absolute;
    right: 6px;
    bottom: 100%;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 14px solid #fff;
  }
}
